import { PickupLocationActivateOrDeactivateRequestInterface } from '@/data/payload/contracts/PickupLocationRequest'

export class PickupLocationRequest
  implements PickupLocationActivateOrDeactivateRequestInterface {
  private isActive: boolean
  private routeIDs: Array<number | undefined>

  constructor(is_active: boolean, route_ids: Array<number | undefined>) {
    this.isActive = is_active
    this.routeIDs = route_ids
  }

  public toPayload(): {
    is_active: boolean
    route_ids: Array<number | undefined>
  } {
    return {
      is_active: this.isActive,
      route_ids: this.routeIDs,
    }
  }
}
