import { container } from 'tsyringe'
import Vue from 'vue'
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from 'vuex-module-decorators'
import store from '@/app/ui/store'
import { Pagination } from '@/domain/entities/Pagination'
import {
  PickupLocation,
  PickupLocations,
} from '@/domain/entities/PickupLocation'
import { PickupLocationPresenter } from '@/app/ui/presenters/PickupLocationPresenter'
import {
  EventBus,
  EventBusConstants,
  ParamsPickupLocationInterface,
  PICKUP_LOCATION_PAGINATION,
  Utils,
} from '@/app/infrastructures/misc'
import { PickupLocationRequest } from '@/data/payload/api/PickupLocationRequest'

export interface PickupLocationState {
  isLoading?: boolean
  isLoadingCity?: boolean
  paginationData?: Pagination
  pickupLocationData?: Array<PickupLocation>
  pickupLocationDetail?: PickupLocation
  pickupLocationCity?: Array<string>
}

@Module({ namespaced: true, store, name: 'pickup-location', dynamic: true })
class PickupLocationController extends VuexModule
  implements PickupLocationState {
  private presenter: PickupLocationPresenter = container.resolve(
    PickupLocationPresenter
  )
  isLoading = false
  isLoadingCity = false
  paginationData: Pagination = new Pagination(1, PICKUP_LOCATION_PAGINATION, 0)
  pickupLocationData: Array<PickupLocation> = [new PickupLocation()]
  pickupLocationDetail: PickupLocation = new PickupLocation()
  pickupLocationCity: string[] = []
  statusActiveDeactivate = ''
  statusActiveDeactivateDetail = ''

  @Action({ rawError: true })
  public getPickupLocations(params: ParamsPickupLocationInterface): void {
    this.setLoading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getPickupLocations(formattedParams)
      .then(res => {
        this.setPickupLocationData(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Pickup Location List Error',
          text:
            error?.status === 400
              ? error?.error?.message?.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getPickupLocation(id: string): void {
    this.setLoading(true)
    this.presenter
      .getPickupLocation(id)
      .then(res => {
        this.setPickupLocationDetail(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Detail Location Error',
          text:
            error?.status === 400
              ? error?.error?.message?.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public activateOrDeactivatePickupLocation(payload: {
    isActive: boolean
    routeIDs: Array<number | undefined>
  }): void {
    this.setLoading(true)
    this.presenter
      .activateOrDeactivatePickupLocation(
        new PickupLocationRequest(payload.isActive, payload.routeIDs)
      )
      .then(() => {
        this.setStatusActiveDeactivate(EventBusConstants.ACTIVATE_DEACTIVATE_PICKUP_LOCATION_SUCCESS)
        this.setStatusActiveDeactivateDetail(EventBusConstants.ACTIVATE_DEACTIVATE_PICKUP_LOCATION_DETAIL_SUCCESS)
      })
      .catch(error => {
        Vue.notify({
          title: 'Activate or Deactivate Pickup Location Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getCity(): void {
    this.setLoadingCity(true)
    this.presenter
      .getCity()
      .then(res => {
        this.setCity(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch data City',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoadingCity(false)
      })
  }

  @Mutation
  private setLoading(bool: boolean): void {
    this.isLoading = bool
  }

  @Mutation
  private setLoadingCity(bool: boolean): void {
    this.isLoading = bool
  }

  @Mutation
  private setPickupLocationData(data: PickupLocations): void {
    this.paginationData = <Pagination>data.pagination
    this.pickupLocationData = <Array<PickupLocation>>data.data
  }

  @Mutation
  private setPickupLocationDetail(data: PickupLocation): void {
    this.pickupLocationDetail = <PickupLocation>data
  }

  @Mutation
  private setCity(data: Array<string>) {
    this.pickupLocationCity = <Array<string>>data
  }

  @Mutation
  public setStatusActiveDeactivate(status: string) {
    this.statusActiveDeactivate = status
  }

  @Mutation
  public setStatusActiveDeactivateDetail(status: string) {
    this.statusActiveDeactivateDetail = status
  }
}

export default getModule(PickupLocationController)
