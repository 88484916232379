












































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import { DataObject } from '@/app/ui/components/DataTableV2/type'
import Badge from '@/app/ui/components/Badge/index.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import EmptyState from '@/app/ui/components/EmptyState/EmptyState.vue'
import { EventBusConstants, Utils } from '@/app/infrastructures/misc'
import CheckBoxCheckedIcon from '@/app/ui/assets/check_box_checked.vue'
import CheckBoxUncheckedIcon from '@/app/ui/assets/check_box_unchecked.vue'
import ModalConfirmation from './components/PickupLocationModal/ModalConfirmation/index.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import {
  HEADERS,
  EnumStatus,
  OPTION_STATUS,
  OptionInterface,
  ParameterPickupLocationInterface,
  ParamsPickupLocationInterface,
  HeadersPickupLocationInterface,
  PICKUP_LOCATION_PAGINATION,
  PICKUP_LOCATION_TITLE_SUCCESS_ACTIVATE,
  PICKUP_LOCATION_TITLE_SUCCESS_DEACTIVATE,
  PICKUP_LOCATION_BODY_SUCCESS_ACTIVATE,
  PICKUP_LOCATION_BODY_SUCCESS_DEACTIVATE,
} from '@/app/infrastructures/misc/Constants'
import controller from '@/app/ui/controllers/PickupLocationController'
import { Dictionary } from 'vue-router/types/router'

@Component({
  components: {
    TextInput,
    Button,
    DropdownSelect,
    LoadingOverlay,
    DataTableV2,
    Badge,
    PaginationNav,
    EmptyState,
    CheckBoxCheckedIcon,
    CheckBoxUncheckedIcon,
    ModalConfirmation,
    Modal,
  },
})
export default class PickupLocation extends Vue {
  optionsStatus: Array<OptionInterface> = OPTION_STATUS
  headers: Array<HeadersPickupLocationInterface> = HEADERS
  controller = controller
  parameters: ParameterPickupLocationInterface = {
    keyword: '',
    status: this.optionsStatus[0],
    city: undefined,
    sortBy: 'asc',
    page: 1,
    perPage: PICKUP_LOCATION_PAGINATION,
  }
  dataChecked: Array<number | undefined> = []
  disabledButtonActive = true
  disabledButtonInActive = true
  modalConfirmation = {
    visible: false,
    status: '',
  }
  modalSuccess = {
    visible: false,
    title: '',
    body: '',
  }
  isSuperAdmin = Utils.isSuperAdmin()

  created(): void {
    const queries = <Record<string, never>>this.$route.query
    if (this.$route.query instanceof Object) {
      this.parameters = {
        keyword: queries.keyword,
        status:
          this.optionsStatus.find(item => item.value === queries?.status) ||
          this.optionsStatus[0],
        city:
          this.cityList.find(item => item.value === queries.city) || undefined,
        sortBy: 'asc',
        page: Utils.alwaysNumber(queries.page) || 1,
        perPage: PICKUP_LOCATION_PAGINATION,
      }
    }

    this.fetchAllDataPickupLocation()
    this.fetchCity()
  }

  @Watch('controller.statusActiveDeactivate')
  onStatusActivateDeactivate(status: string): void {
    if (status !== '' && status === EventBusConstants.ACTIVATE_DEACTIVATE_PICKUP_LOCATION_SUCCESS) {
      this.modalConfirmation = {
        visible: false,
        status: '',
      }
      this.modalSuccess.visible = true
      this.fetchAllDataPickupLocation()
    }

    controller.setStatusActiveDeactivate('')
  }

  get cityList(): Array<OptionInterface> {
    const cities = controller.pickupLocationCity.sort().map(item => ({
      label: item,
      value: item,
    }))
    cities.unshift({
      label: 'All',
      value: '',
    })
    return cities
  }

  get params(): ParamsPickupLocationInterface {
    return {
      ...this.parameters,
      status: this.parameters.status?.value,
      city: this.parameters.city?.value || undefined,
    }
  }

  get pickupLocationData(): Array<DataObject[]> {
    return controller.pickupLocationData?.map(item => {
      return [
        {
          value: `${item.routeId}-${item.city}`,
          customStyle: { maxWidth: '300px', minWidth: '300px' },
        },
        {
          value: item.subDistrict,
          customStyle: { maxWidth: '300px', minWidth: '300px' },
        },
        {
          value: item.status,
          customStyle: { maxWidth: '210px', minWidth: '210px' },
        },
        {
          value: item.routeId,
          customStyle: { maxWidth: '200px', minWidth: '200px' },
        },
      ]
    })
  }

  private fetchAllDataPickupLocation(resetPage = false): void {
    if (resetPage) {
      this.parameters.page = 1
    }
    this.dataChecked = []

    controller.getPickupLocations(this.params)
  }

  private fetchCity(): void {
    controller.getCity()
  }

  private getType(status: string): string {
    if (status?.toUpperCase() === EnumStatus.ACTIVE) {
      return 'success'
    }
    return 'error'
  }

  private getStatus(status: string): string {
    return Utils.toCapitalize(status?.replace(/_/g, ' '))
  }

  private ToggleCheckbox(id: never): void {
    if (this.dataChecked.includes(id)) {
      this.dataChecked = this.dataChecked.filter(item => {
        return item !== id
      })
    } else {
      this.dataChecked.push(id)
    }
  }

  private ToggleCheckboxAll(): void {
    if (this.dataChecked.length === controller.pickupLocationData.length) {
      this.dataChecked = []
    } else {
      controller.pickupLocationData.forEach(item => {
        if (!this.dataChecked.includes(item.routeId)) {
          this.dataChecked.push(item.routeId)
        }
      })
    }
  }

  private checkStatus(item: number | undefined): string | undefined {
    return controller.pickupLocationData.find(pickup => {
      return pickup.routeId === item
    })?.status
  }

  private showModalConfirmation(visible = false, status = ''): void {
    this.modalConfirmation = {
      visible: visible,
      status: status,
    }
  }

  private handleEmitSubmit(): void {
    if (this.dataChecked.length > 0 && this.isSuperAdmin) {
      const isActive = !(
        this.checkStatus(this.dataChecked[0])?.toUpperCase() ===
        EnumStatus.ACTIVE
      )
      controller.activateOrDeactivatePickupLocation({
        isActive: isActive,
        routeIDs: this.dataChecked,
      })
      this.modalSuccess = {
        visible: false,
        title: isActive
          ? PICKUP_LOCATION_TITLE_SUCCESS_ACTIVATE
          : PICKUP_LOCATION_TITLE_SUCCESS_DEACTIVATE,
        body: isActive
          ? PICKUP_LOCATION_BODY_SUCCESS_ACTIVATE
          : PICKUP_LOCATION_BODY_SUCCESS_DEACTIVATE,
      }
    }
  }

  public handleSuccess(): void {
    this.modalSuccess = {
      visible: false,
      title: '',
      body: '',
    }
  }

  @Watch('dataChecked', { deep: true })
  onDataCheckedChange(): void {
    let active = 0
    let inActive = 0
    this.dataChecked.forEach(item => {
      const status = this.checkStatus(item)
      if (status?.toUpperCase() === EnumStatus.ACTIVE) {
        active++
      } else {
        inActive++
      }
    })
    if (this.dataChecked.length > 0 && this.dataChecked.length === active) {
      this.disabledButtonInActive = false
    } else {
      this.disabledButtonInActive = true
    }
    if (this.dataChecked.length > 0 && this.dataChecked.length === inActive) {
      this.disabledButtonActive = false
    } else {
      this.disabledButtonActive = true
    }
  }

  @Watch('params')
  onParamsChanged(val: Dictionary<string | (string | null)[] | null | undefined> | undefined): void {
    this.$router
      .replace({
        query: val,
      })
      .catch(() => false)
  }

  beforeDestroy(): void {
    controller.setStatusActiveDeactivate('')
  }
}
